:root {
    --palette1-color1: #401F71;
    --palette2-color2: #E0A75E;
     
    --palette3-color1: #A79277;
    --palette3-color2: #D1BB9E;
    --palette3-color3: #EAD8C0;
    --palette3-color4: #FFF2E1;

    --palette4-color1: #973131;
    --palette4-color2: #E0A75E;
    --palette4-color3: #F9D689;
    --palette4-color4: #F5E7B2;
}

  

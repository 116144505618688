#contact_us {
  background-color: #A79277;
}

.contactUs-logo {
  position: relative;
  min-height: 120px;
}

.contactUs-logo > img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  height: 50%;
  min-height: 100px;
  max-height: 200px;
  width: 50%;
  min-width: 100px;
  max-width: 200px;
}


@media (min-width: 768px) { 
.contactUs-logo > img {
  left: 75%;
}
}
.link-socials > a > img {
  height: 50px;
  width: 50px;
  padding: 10px;
}

.contact-us-btn, .contact-us-btn:hover {
color: #F5E7B2 !important;
background-color: #973131 !important;
}

.contact-channels > p > a {
  color: #973131;
}

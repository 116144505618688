.article-content {
    margin-top: 200px;
}

.cover {
    position: relative;
    overflow-y: visible;
    min-height: 200px;
}

.cover > img {
    position: absolute;
    max-height: 900px;
    max-width: 90%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    box-shadow: none;
    z-index: -100;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-25%);
}

.blog-image {
    /* max-height: 300px; */
    /* width: auto; */
}

/* Hero section */
/* Hero cover picture at center */
#inicio {
  position: relative;
  /* background-image: url("../images/hero-image-background.png"); */
  background-image: url("../images/background hero section.svg");
  width: 100%;
  min-height: 100svh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  box-shadow: none;
}

/* Gradient color to hero description */
.hero-section > .row > .hero-discription {
  color: #F5E7B2;
  background: linear-gradient(90deg, rgba(151, 49, 49, 0.0) 0%,  
  rgba(151, 49, 49, 0.6) 100%);
}
/* rgb(151, 49, 49) */
/* NOTE: Keep an eye on the size of the logo */
.hero-section > .row > .hero-logo {
  height: 70vh;
  position: relative;
}

.hero-button, .hero-button:hover {
  color: #F5E7B2 !important;
  background-color: #973131 !important;

}

@keyframes LogoWifia {
  0% {
    opacity: 0%;
  }
  19% {
    opacity: 0%;
  }
  20% {
    opacity: 100%;
  }
  100% {
    opacity: 100%;
  }
}
@keyframes LogoWifib {
  0% {
    opacity: 0%;
  }
  39% {
    opacity: 0%;
  }
  40% {
    opacity: 100%;
  }
  100% {
    opacity: 100%;
  }
}
@keyframes LogoWific {
  0% {
    opacity: 0%;
  }
  59% {
    opacity: 0%;
  }
  60% {
    opacity: 100%;
  }
  100% {
    opacity: 100%;
  }
}
@keyframes LogoWifid {
  0% {
    opacity: 0%;
  }
  79% {
    opacity: 0%;
  }
  80% {
    opacity: 100%;
  }
  100% {
    opacity: 100%;
  }
}

.hero-logo1, .hero-logo2, .hero-logo3, .hero-logo4, .hero-logo5 {
  position: absolute;
  top: 15%;
  right: 0%;
  transform: translate(0%,0%);
  opacity: 100%;
}

.hero-logo5 {
  opacity: 100%;
  z-index: 5;
}

.hero-logo4 {
  animation: LogoWifia 2s infinite;
  z-index: 4;
}

.hero-logo3 {
  animation: LogoWifib 2s infinite;
  z-index: 3;
}

.hero-logo2 {
  animation: LogoWific 2s infinite;
  z-index: 2;
}

.hero-logo1 {
  animation: LogoWifid 2s infinite;
  z-index: 1;
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 576px) { 
  .hero-section > .row > .hero-logo {
    height: auto;
    position: absolute;
    right: 5%;
    top: 15%;
  }

  .hero-section > .row > .hero-discription {
    position: absolute;
    right: 0%;
    bottom: 0%;
  }

  .hero-section > .row > .hero-cta {
    position: absolute;
    left: 5%;
    bottom: 5%;
  }
}

.cta-title {
  color: #973131;
}
.CTA > .row > .col > form {
  background-color: #FFF2E1;
  border-radius: 50px;
  border: solid #973131 1px;
}

.form-post-success {
  border-radius: 10px;
  border: 2px solid white;
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.fade-toggle {
  opacity: 1;
}

.cta-btn {
  color: #F5E7B2;
  background-color: #973131;
}

.form-group > label {
  font-size: 14px;
}

.form-group > input::placeholder {
  font-size: 12px;
}

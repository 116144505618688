.article-cover {
  min-height: 150px;
}

.article-cover-visible {
  position: relative;
  width: 100%;
  height: 200px;
  overflow-y: visible;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  box-shadow: none;
}

.article-cover-visible > img {
  /* width: auto; */
  min-height: 600px;
  max-width: 100vw;
  aspect-ratio: 14/9;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  z-index: -100;
}

@media (min-width: 768px) { 
  .article-cover-visible > img {
  min-height: 800px;
  }
}
@media (min-width: 992px) { 
  .article-cover-visible > img {
  min-height: 1000px;
  }
}
@media (min-width: 1200px) { 
  .article-cover-visible > img {
  min-height: 1200px;
  }
}

.article-image {
  max-height: 300px;
  width: 100%;
  overflow: hidden;
}

.article-image > img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 10px;
}

#inteligencia {
  background: #FAFAFA !important;
}

.hero-column  {
  list-style-type: disc;
  font-family: 'Poppins',Helvetica,Arial,Lucida,sans-serif;
  font-weight: 500;
  color: #000000;
  word-wrap: break-word;
  list-style-type: disc;
  line-height: 26px;
  box-sizing: border-box;
  font-size: 100%;
  font-size: 14px;
}

.hero-column > div  {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 2px 2px 0px gray,
  0px 0px 2px 0px gray;
}

.tech-title {
  box-sizing: border-box;
  font-family: 'Poppins',Helvetica,Arial,Lucida,sans-serif;
  color: #973131 !important;
  line-height: 1em;
  font-weight: 500;
  text-align: center;
  word-wrap: break-word;
}

.hero-column-title > h1 {
  font-size: 22px;
  font-weight: 700;
  color: #973131 ;
}

.page-section > .row > .col {
  border-radius: 0px;
  background: none;
  box-shadow: none;
}

@keyframes firstLoadHero {
  0% {
    opacity: 0;
    transform: translate(-50%,-25%);
  }
  100% {
    opacity: 1;
    transform: translate(-50%,0%);
  }
}

.hero-column-image > img {
  width: 100%;
  height: 100%;
  max-height: 200px;
  max-width: 200px;
  position: relative;
  transform: translate(-50%,0);
  top: 0%;
  left: 50%;
  background-color: white;
  border-radius: 5%;
  /* box-shadow: 0px 2px 2px 0px gray, */
  /* 0px 0px 2px 0px gray; */

  transition: ease-in-out;
  animation-name: firstLoadHero;
  animation-duration: 1.5s;
  animation-iteration-count: 1;
}

.hero-column-content > p {
  font-size: 14px;
}

/* .hero-column > li { */
/*   list-style-type: none; */
/*   text-align: justify; */
/*   text-justify: inter-word; */
/* } */

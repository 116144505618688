/* Navbar styles */
.navbar {
  position: fixed !important;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 2000;
  padding: 0 !important;
  margin: 0 !important;
}

.navbar > .container-fluid {
  padding: 0;
}

.navbar-toggler > img {
  max-height: 100px;
  max-width: 100px;
}

.navbar-logo {
  height: 50px;
  width: 50px;
}

.nav-item {
  position: relative;
}

.nav-link.btn {
  cursor: pointer;
  text-align: start;
}

.product-list-collape, .submenu-list-collape {
  max-height: 80vh;
  overflow-y: scroll;
}

.product-list-ul, .submenu-list-ul {
  margin: 0px;
  padding: 0px;
}

.nav-item-products, .nav-item-submenu {
  list-style: none;
  border: solid 1px black;
  border-radius: 3px;
  margin-bottom: 5px;
  box-shadow: 0px 3px 3px 0px black;
}

.nav-item-products-final, .nav-item-submenu-final {
  background-color: var(--palette3-color4);
}

.nav-item-products-final > a, .nav-item-submenu-final > a {
  color: var(--palette1-color4);
}

#casos {
  background-color: #FFF2E1;
}

.tecnologias-titulo {
  color: var(--palette1-color1) !important;
  font-family: 'Poppins',Helvetica,Arial,Lucida,sans-serif;
  font-weight: 700;
  text-transform: uppercase;
}

.tech-column > div  {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 2px 1px 0px var(--palette3-color2);
}

.tech-title > h1 {
  font-size: 22px;
  font-weight: 600;
}

.tech-title {
  box-sizing: border-box;
  font-family: 'Poppins',Helvetica,Arial,Lucida,sans-serif;
  color: #973131 !important;
  line-height: 1em;
  font-weight: 500;
  text-align: center;
  word-wrap: break-word;
}


.tech-details  {
  list-style-type: disc;
  font-family: 'Poppins',Helvetica,Arial,Lucida,sans-serif;
  font-weight: 500;
  color: #000000;
  word-wrap: break-word;
  list-style-type: disc;
  line-height: 26px;
  box-sizing: border-box;
  font-size: 100%;
}

.tech-details > p {
  font-size: 14px;
}

@keyframes firstLoadTech {
  0% {
    opacity: 0;
    transform: translate(0%,-25%);
  }
  100% {
    opacity: 1;
    transform: translate(0%,0%);
  }
}

.tech-image > img {
  width: 100%;
  height: 100%;
  max-height: 200px;
  max-width: 200px;
  position: relative;
  transform: translate(-50%,0);
  top: 0%;
  left: 50%;
  background-color: white;
  border-radius: 5%;

  transition: ease-in-out;
  animation-name: firstLoadHero;
  animation-duration: 1.5s;
  animation-iteration-count: 1;
}

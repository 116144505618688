  .page-section {
    width: 100vw;
  }

/* NOTE: keep the height at 100% viewheight */

/* Small devices (landscape phones, 576px and up) */
/* @media (min-width: 576px) {  */
/*   .page-section { */
/*     min-height: 75vh; */
/*   } */
/* } */

/* Medium devices (tablets, 768px and up) */
/* @media (min-width: 768px) {  */
/*   .page-section { */
/*     min-height: 50vh; */
/*   } */
/* } */

/* Smooth gradient section division  */
/* .page-section { */
/*   background: rgb(210, 180, 140); */
/*   background: linear-gradient(180deg, rgba(210, 180, 140, 1) 0%,   */
/*   rgba(245, 222, 179, 1) 100%); */
/* } */
/**/
/* .page-section > .row > .col { */
/*   border-radius: 50px; */
/*   background: #ffffff; */
/*   box-shadow: inset -36px -36px 72px #d9d9d9, */
/*   inset 36px 36px 72px #ffffff; */
/* } */
/**/

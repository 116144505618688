#servicios {
  height: 300px;
  width: auto;
}

.servicios-section {
  background: var(--palette3-color3);
}

.carousel-image-container {
  position: relative;
  height: 300px;
}

.carousel-text-title {
  font-weight: 700;
}
.carousel-image {
  position: absolute;
  height: 300px;
  width: 300px;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}

#product {
    width: 98%;
}

.Producto {
    font-family: 'Poppins', Helvetica, Arial, Lucida, sans-serif;
}

.principal-box-product {
    width: 100%;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    background-color: #f5f5f5;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.informacion {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;
}

.informacion h1,
.informacion h3 {
    color: #333;
    text-align: left;
}

.image-container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
    width: 100%;
}

.main-image {
    width: 600px;
    height: 600px;
    border-radius: 8px;
    margin-right: 10px;
}

.thumbnail-container {
    display: flex;
    flex-direction: column;
}

.thumbnail-container button {
    background: none;
    border: none;
    margin-bottom: 10px;
    cursor: pointer;
}

.thumbnail {
    width: 60px;
    height: auto;
    border: 2px solid #ddd;
    border-radius: 5px;
    transition: border-color 0.3s ease;
}

.thumbnail-container button:hover .thumbnail,
.thumbnail-container button:focus .thumbnail {
    border-color: #007bff;
}

.caracteristicas {
    display: flex;
    margin-top: 20px;
}

.accesorios_recomendados {
    display: flex;
    flex-direction: column;
}

.accesorio-box {
    display: flex;
    justify-content: initial; /* Separa los accesorios de forma equitativa */
    gap: 20px; /* Añade espacio entre los accesorios */
    flex-wrap: wrap;
    width: 100%;
}

.accesorio-box h3 {
    font-size: 15px;
}

.accesorios-boxs {
    width: 10%; /* Ajusta el ancho para que se vean mejor varios en pantalla */
    background-color: #fff;
    padding: 10px;
    border-radius: 8px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.accesorios-boxs:hover {
    transform: translateY(-5px); /* Añade un efecto de elevación */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    background-color: #f0f0f0;
}

.accesorio-image {
    width: 100%;
    border-radius: 5px;
}

.accesorios-tabla {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    font-family: Arial, sans-serif;
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.05);
  }
  
  .accesorios-tabla th, .accesorios-tabla td {
    padding: 12px 20px;
    text-align: left;
    border-bottom: 1px solid #e0e0e0;
  }
  
  .accesorios-tabla th {
    background-color: #f5f5f5;
    color: #555555;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.05em;
  }
  
  .accesorios-tabla td {
    color: #666666;
  }
  
  .accesorios-tabla tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .accesorios-tabla tr:hover {
    background-color: #f1f1f1;
  }
  
  .accesorios-tabla td a {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #4a4a4a;
    font-weight: 600;
    width: 17%;
  }
  
  .accesorios-tabla td a:hover {
    color: #007BFF; /* Suave cambio a azul para interactividad */
  }
  
  .accesorio-thumbnail {
    width: 50px;
    height: 50px;
    margin-right: 10px;
  }
  
  .accesorios-tabla td img {
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.05);
    width: 100%;
  }

.accesorio-url{
    display: flex;
}
.accesorio-url-text {
    align-content: center;
}

.vibracion-rango,
.funciones {
    width: 50%;
}

.funciones ul {
    list-style: none;
    padding: 0;
}

.funciones ul li {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    font-size: 16px;
    color: #333;
}

.check-icon {
    color: #007bff;
    font-size: 18px;
    margin-right: 8px;
}

.otras-caracteristicas {
    margin-top: 20px;
}

.otras-caracteristicas p {
    font-weight: bold;
    color: #007bff;
}

.caracteristicas-tabla {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
}

.caracteristicas-tabla td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #dddddd;
    color: #333;
}

.caracteristicas-tabla td:first-child {
    font-weight: bold;
    background-color: #f8f8f8;
}

.caracteristicas-tabla tr:last-child td {
    border-bottom: none;
}

/* Estilos de pestañas */
.tabs {
    display: flex;
    margin-top: 20px;
    border-bottom: 2px solid #ddd;
}

.tabs button {
    background-color: transparent;
    border: none;
    font-size: 16px;
    padding: 10px 20px;
    cursor: pointer;
    transition: color 0.3s ease, border-bottom 0.3s ease;
}

.tabs button:hover {
    color: #007bff;
}

.tabs button:focus {
    outline: none;
}

.tabs button.active {
    border-bottom: 3px solid #007bff;
    color: #007bff;
    font-weight: bold;
}

.content {
    margin-top: 20px;
}

.accesorio-detalles {
    background-color: #f8f9fa;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.accesorio-detalles h3 {
    color: #007bff;
    margin-bottom: 10px;
}

.accesorio-detalles p {
    font-size: 16px;
    color: #333;
}
